import React, { useContext, useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table'
import formatDate from '../Utility/Utility'
import { useUser } from '../Provider/UserContext'
import TabContext from '../Provider/UserContext'
import axios from 'axios'
import { Variables } from '../Variables'
import { VisitProvider } from '../Provider/Visitprovider'
import signLockService from '../POC/SignLock/Service'
import { DDList } from './DDList'
import SupervisorContext from '../Provider/Visitprovider'
import SoapNoteMain from '../PDFGenerator/NotesPDF/SoapNoteMain'
import { SoapNoteService } from '../ProgressNote/SoapNote/SoapNoteService'
import ProgressNoteMain from '../PDFGenerator/NotesPDF/ProgressNoteMain'
import { ProgressNoteService } from '../ProgressNote/ProgressNotes/ProgressNoteService'
import CHCMedicalPDF from '../PDFGenerator/CHCMedicalPDF'
import { PocService } from '../POC/Service/PocService'
import DischargeSumPDF from '../PDFGenerator/NotesPDF/DischargeSumPDF'
import { DischargeSummaryService } from '../ProgressNote/DischargeSummary/DischargeSummaryService'
import { saveAs } from 'file-saver'
import { toast } from 'react-toastify'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'

const apiUrl = Variables.API_URL
const apiUrlSLPCore = Variables.USER_API_URL

function formatFromToTime(fromDateStr, toDateStr) {
  // Helper function to format time in 'HH:mm' format
  const pad = (number) => (number < 10 ? '0' + number : number)

  // Convert date strings to Date objects
  const fromDate = new Date(fromDateStr)
  const toDate = new Date(toDateStr)

  // Get the current date
  const currentDate = new Date()

  // Format the from and to times
  const fromTime = `${pad(fromDate.getHours())}:${pad(fromDate.getMinutes())}`
  const toTime = `${pad(toDate.getHours())}:${pad(toDate.getMinutes())}`

  // Format the current date in 'yyyy-MM-dd' format
  const formattedDate = `${currentDate.getFullYear()}-${pad(
    currentDate.getMonth() + 1,
  )}-${pad(currentDate.getDate())}`

  // Return the formatted string with date and from-to times
  return `${formattedDate} ${fromTime}-${toTime}`
}

export function UserDetail() {
  const VisitId = useContext(VisitProvider)
  const { userDetails } = useUser()
  const activeTab = useContext(TabContext)
  const [headerPOC, setHeaderPOC] = useState('')
  const [headerPN, setHeaderPN] = useState('')
  const [headerDS, setHeaderDS] = useState('')
  const [dynamicVisitId, setDynamicVisitId] = useState(null)
  const [dynamicVisitType, setDynamicVisitType] = useState(null)
  const [isShowButton, setisShowButton] = useState(false)

  const [clientId, setClientId] = useState(null)
  const [assLists, setAssLists] = useState([])
  const [passtVisitLists, setPasstVisitLists] = useState([])
  const [superVisorLists, setSuperVisorLists] = useState([])
  const [userDetailsC, setUserDetailsC] = useState(null)
  const [ddlVisitId, setddlVisitId] = useState()

  const [soapNoteData, setSoapNoteData] = useState()
  const [isProgressNote, setIsProgressNote] = useState()
  const soapNoteService = new SoapNoteService()

  const [progressNoteData, setProgressNoteData] = useState()
  const progressNoteService = new ProgressNoteService()

  const [pocData, setPocData] = useState()
  const pocService = new PocService()

  const [dischargeData, setDischargeData] = useState()
  const dischargeNoteService = new DischargeSummaryService()

  const { supervisorValue, setSupervisorValue } = useContext(SupervisorContext)

  useEffect(() => {
    if (userDetails) {
      setClientId(userDetails.Clientid)
      setUserDetailsC(userDetails)
      setddlVisitId(VisitId)
      document.title = userDetails.FullNameWithDOB
       //alert(JSON.stringify(userDetails));
    }
  }, [userDetails, VisitId])

  async function getAssessmentList() {
    try {
      const response = await signLockService.getAssessmentList(clientId)
      const combinedDates = formatFromToTime(
        userDetailsC.Scheduleddatefrom,
        userDetailsC.Scheduleddateto,
      )
      const updatedAssessmentList = [
        // { value: VisitId, text: combinedDates },
        ...response,
      ]
      //console.log(JSON.stringify(updatedAssessmentList))
      setAssLists(updatedAssessmentList)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const getSuperVisorList = async (therapistId) => {
    if (therapistId) {
      try {
        const response = await axios.get(
          apiUrlSLPCore +
            'User/GetAssignedSupervisor/?therapistId=' +
            therapistId +
            '&supervisiorType=1',
        )
        const data = response.data.ListResult
        //alert(JSON.stringify(data.LastPocDate));
        const list = data.map((item) => ({
          value: item.SupervisorId,
          text: item.SupervisorName,
        }))
        //alert(JSON.stringify(list));
        setSuperVisorLists(list)
      } catch (error) {
        console.log('Error fetching user details:', error)
      }
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      if (VisitId != null && clientId !== null) {
        const visitdate = formatDate(
          userDetails.Scheduleddateto,
          false,
          false,
        ).toString()
        try {
          const response = await axios.get(
            apiUrl +
              `ProgressDis/GetHeader/${VisitId}/${clientId}/${visitdate}`,
          )
          const data = response.data.Result
          //alert(JSON.stringify(data));
          setHeaderPOC(data.LastPocDate)
          setHeaderPN(data.LastPNDate)
          setHeaderDS(data.LastDSDate)
        } catch (error) {
          console.log('Error fetching user details:', error)
        }
      }
    }

    if (clientId !== null) {
      getAssessmentList()
      fetchData()
    }
    if (userDetails != null) {
      if (userDetails.IsSLPA === 1) {
        getSuperVisorList(userDetails.TherapistId)
      }
    }
  }, [clientId, VisitId])

  const [serviceLine, setServiceLine] = useState('1')
  const [loadingSl, setLoadingSl] = useState(false)

  const handleSelectServiceLine = (event) => {
    const treatmentTypeId = event.target.value
    setServiceLine(treatmentTypeId)

    if (treatmentTypeId === '2' || treatmentTypeId === '3') {
      const visitdate = formatDate(
        userDetails.Scheduleddateto,
        false,
        false,
      ).toString()
      setLoadingSl(true)
      pocService
        .GetPastVisits(clientId, visitdate, treatmentTypeId) //clientId, visitdate, treatmentTypeId)
        .then((result) => {
          //alert(JSON.stringify(result))
          if (result.data.ListResult && result.data.ListResult.length > 0) {
            setPasstVisitLists(result.data.ListResult)
          } else {
            setPasstVisitLists(null) // Set to null if no data is returned
          }
          setLoadingSl(false)
        })
        .catch((error) => {
          setLoadingSl(false)
          //console.error('Error fetching past visits:', error)
        })
    }
  }

  const [selectedVisitId, setSelectedVisitId] = useState('0')

  const downloadFile = (fileUrl, filename) => {
    axios
      .get(fileUrl, { responseType: 'blob' })
      .then((response) => {
        if (response.status === 200) {
         // alert(response.data)
          // Create a URL for the file
          const fileURL = window.URL.createObjectURL(new Blob([response.data]))
          const fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', filename) // Use filename from the result
          document.body.appendChild(fileLink)

          fileLink.click()

          // Clean up and remove the link
          fileLink.parentNode.removeChild(fileLink)
        } else {
          alert('No file found at this address.')
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          alert('No file found at this address.')
        } else {
          console.error('Error downloading the PDF file', error)
          alert('No file found at this address.')
        }
      })
  }
  const handleSelectReport = (event) => {
    //alert('hi')
    const selectedVisitId = event.target.value
    // alert(selectedVisitId)
    setSelectedVisitId(selectedVisitId)
    //alert("hih")
    pocService
      .GetUploadedFile(selectedVisitId, 11) // Replace with selectedVisitId if needed
      .then((result) => {
        const { filename, FileURLPath } = result.Result

        if (!FileURLPath) {
          alert('There is no file for this visit(' + selectedVisitId + ').')
          return
        }

        downloadFile(FileURLPath, filename) // Call the reusable download function
      })
      .catch((error) => {
        setLoadingSl(false)
        console.error('Error fetching uploaded file:', error)
        alert('No file found at this address.')
      })
  }

  // const downloadFileST = (fileUrl, filename) => {
  //   fetch(fileUrl, {
  //     method: 'GET',
  //     credentials: 'include' // if you need to send cookies or credentials
  //   })
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error('Network response was not ok');
  //       }
  //       return response.blob();
  //     })
  //     .then((blob) => {
  //       // Create a URL for the blob
  //       const fileURL = window.URL.createObjectURL(blob);
  //       const fileLink = document.createElement('a');
  
  //       fileLink.href = fileURL;
  //       fileLink.setAttribute('download', filename);
  
  //       // Append the link to the body
  //       document.body.appendChild(fileLink);
  //       fileLink.click();
  
  //       // Clean up
  //       fileLink.parentNode.removeChild(fileLink);
  //       window.URL.revokeObjectURL(fileURL);
  //       console.log('File downloaded successfully');
  //     })
  //     .catch((error) => {
  //       console.error('There was an error downloading the file:', error);
  //     });
  // };
  

  const downloadFileST = (fileUrl, filename) => {
  
    //if()
    return axios
      .get(fileUrl, { responseType: 'blob' })
      .then((response) => {
        if (response.status === 200) {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        
          const fileLink = document.createElement('a')
         
          fileLink.href = fileURL
          fileLink.setAttribute('download', filename)
          document.body.appendChild(fileLink)
          
          fileLink.click()
          fileLink.parentNode.removeChild(fileLink)
          return true // File downloaded successfully
        } else {
          console.error(
            'Failed to download file:',
            response.status,
            response.statusText,
          )
          return false // File download failed
        }
      })
      .catch((error) => {
        console.error('Error downloading the PDF file:', error)
        return false // File download failed
      })
  }
  const handleSelect = (event) => {
    const valueControl = event.target.value

    if (valueControl !== VisitId) {
      setddlVisitId(valueControl)
    } else {
      setddlVisitId(VisitId)
    }
    pocService
      .GetUploadedFile(valueControl, 11) //1418539.pdf
      .then((result) => {
        const { filename, FileURLPath } = result.Result

        if (FileURLPath) {
          //alert(FileURLPath)
          return downloadFileST(FileURLPath, filename)
        }
        return false // No FileURLPath, file download not attempted
      })
      .catch((error) => {
        //alert(error)
        //console.error('Error fetching handleSelect file:', error)

        //alert('sd')
        //return

        const selectedOption = assLists.filter(
          (item) => item.value == valueControl,
        )
        // alert(JSON.stringify(selectedOption))
        if (selectedOption.length > 0) {
          const selectedVisitTypeId = selectedOption[0].visitTypeId
          const isProgressNote = selectedOption[0].isProgressNote

          if (selectedVisitTypeId === 4) {
            setDynamicVisitType(selectedVisitTypeId)
            setDynamicVisitId(valueControl)
            setIsProgressNote(isProgressNote)
          } else if (isProgressNote === false && selectedVisitTypeId === 2) {
            // alert(JSON.stringify(selectedVisitTypeId))
            setDynamicVisitType(selectedVisitTypeId)
            setDynamicVisitId(valueControl)
            setIsProgressNote(isProgressNote)
          } else if (isProgressNote === true && selectedVisitTypeId === 2) {
            setDynamicVisitType(selectedVisitTypeId)
            setDynamicVisitId(valueControl)
            setIsProgressNote(isProgressNote)
          } else if (selectedVisitTypeId === 1 || selectedVisitTypeId === 3) {
            //alert(valueControl);
            setDynamicVisitType(selectedVisitTypeId)
            setDynamicVisitId(valueControl)
            setIsProgressNote(isProgressNote)
          }

          setisShowButton(false)
        }
      })
  }
  useEffect(() => {
    const fetchSoapNoteData = () => {
      if (clientId) {
        soapNoteService
          .getSoapNoteData(dynamicVisitId, clientId, dynamicVisitType)
          .then((dataFromResponse) => {
            // alert(JSON.stringify(dataFromResponse))
            setSoapNoteData(dataFromResponse)
          })
          .catch((error) => {
            console.error('Error fetching fetchSoapNoteData data:', error)
          })
      }
    }

    const fetchProgressSumData = () => {
      if (clientId) {
        progressNoteService
          .getProgressNotes(dynamicVisitId, clientId)
          .then((dataFromResponse) => {
            setProgressNoteData(dataFromResponse)
          })
          .catch((error) => {})
      }
    }

    const fetchPOCData = () => {
      if (clientId) {
        pocService
          .getPocData(dynamicVisitId)
          .then((dataFromResponse) => {
            setPocData(dataFromResponse)
          })
          .catch((error) => {})
      }
    }
    const fetchDischargeSumData = () => {
      dischargeNoteService
        .getProgressNotes(dynamicVisitId, clientId,dynamicVisitType)
        .then((dataFromResponse) => {
          setDischargeData(dataFromResponse)
        })
        .catch((error) => {
          console.error('Error fetching fetchDischargeSumData data:', error)
        })
    }
    if (dynamicVisitId) {
      if (isProgressNote === false && dynamicVisitType === 2) {
        fetchSoapNoteData()
      } else if (isProgressNote === true && dynamicVisitType === 2) {
        fetchProgressSumData()
      } else if (dynamicVisitType === 1 || dynamicVisitType === 3) {
        fetchPOCData()
      } else if (dynamicVisitType === 4) {
        fetchDischargeSumData()
      }
    }
  }, [dynamicVisitId, dynamicVisitType])

  const handleSelectSupervisor = (selectedValue) => {
    const valueControl = selectedValue.target.value
    setSupervisorValue(valueControl)
  }
  const [isTextTruncated, setIsTextTruncated] = useState(true)

  const toggleTextTruncation = () => {
    setIsTextTruncated(!isTextTruncated)
  }
  return (
    <>
      {userDetails ? (
        <Container fluid>
           <Row className='justify-content-md-left'>
            <Col md='auto'>
              <img
                alt='logo'
                style={{ width: '180px', height: '70px' }}
                src={
                  userDetails?.BrandLogo
                    ? './logoImage/' + userDetails?.BrandLogo
                    : './logoImage/logo.png'
                }
              />
            </Col>
            <Col>
              <Table
                responsive='sm'
                bordered
                style={{
                  width: '100%',
                  marginLeft: '10px',
                  padding: '50px',
                  border: '1px solid #f0f0f0',
                }}
              >
                <tbody>
                  <tr>
                    <td style={{ width: '13%' }}>
                      <h6>{userDetails.FullName.toUpperCase()}</h6>
                    </td>
                    <td style={{ width: '4%' }}>
                      <b>Age</b>
                    </td>
                    <td style={{ width: '5%' }}>
                      <b>Gender</b>
                    </td>
                    <td style={{ width: '12%' }}>
                      <b>Primary Diagnosis</b>
                    </td>
                    {activeTab === 'SN' && (
                      <td>
                        <b>Visit</b>
                      </td>
                    )}
                    <td>
                      <b>Care Team</b>
                    </td>
                    {activeTab === 'SN' && (
                      <>
                        <td>
                          <b>Date/Time of Assess</b>
                        </td>
                        {userDetails.IsSLPA === 1 && (
                          <td>
                            <b>Supervisor</b>
                          </td>
                        )}
                      </>
                    )}
                    {activeTab === 'PN' && (
                      <>
                        <td>
                          <b>Start of Care</b>
                        </td>
                        <td>
                          <b>Date of Progress Note</b>
                        </td>
                      </>
                    )}
                    {activeTab === 'DS' && (
                      <>
                        <td>
                          <b>Start of Care</b>
                        </td>
                        <td>
                          <b>Date of Discharge</b>
                        </td>
                      </>
                    )}
                  </tr>
                  <tr>
                    <td>
                      <b>DOB:</b> {formatDate(userDetails.DOB, false, true)}
                    </td>
                    <td>{userDetails.Age}</td>
                    <td>{userDetails.Gender}</td>
                    <td>{userDetails.Diagnosisid1}</td>
                    {activeTab === 'SN' && <td>{userDetails.VisitStatus}</td>}
                    <td>
                      {userDetails.CSTeam
                        ? isTextTruncated
                          ? `${userDetails.CSTeam.slice(0, 10)}...`
                          : userDetails.CSTeam
                        : 'NA'}
                      {userDetails.CSTeam && (
                        <>
                          {isTextTruncated ? (
                            <span
                              onClick={toggleTextTruncation}
                              style={{ cursor: 'pointer', color: 'blue' }}
                            >
                              more...
                            </span>
                          ) : (
                            <span
                              onClick={toggleTextTruncation}
                              style={{ cursor: 'pointer', color: 'blue' }}
                            >
                              {' '}
                              less...
                            </span>
                          )}
                        </>
                      )}
                    </td>
                    {activeTab === 'SN' && (
                      <>
                        <td>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <b>Service Line:</b>
                            <>
                              {' '}
                              <select
                                className='form-select form-select-sm'
                                onChange={handleSelectServiceLine}
                                value={serviceLine}
                                style={{ width: '70px', marginLeft: '10px' }}
                              >
                                <option value='1'>ST</option>
                                <option value='2'>PT</option>
                                <option value='3'>OT</option>
                              </select>
                              {serviceLine === '1' && (
                                <select
                                  className='form-select form-select-sm ml-2'
                                  onChange={handleSelect}
                                  value={ddlVisitId}
                                  style={{ width: '300px' }}
                                >
                                  <option value='0'>
                                    -- Select a visit --
                                  </option>
                                  {assLists.map((option, index) => (
                                    <option
                                      key={index}
                                      data-visittypeid={option.visitTypeId}
                                      value={option.value}
                                    >
                                      {option.text}
                                    </option>
                                  ))}
                                </select>
                              )}
                              {(serviceLine === '2' ||
                                serviceLine === '3') && (
                                <>
                                  {loadingSl ? (
                                    <div className='ml-2'>
                                      Loading...
                                    </div>
                                  ) : passtVisitLists &&
                                    passtVisitLists.length > 0 ? (
                                    <select
                                      className='form-select form-select-sm ml-2'
                                      onChange={handleSelectReport}
                                      value={selectedVisitId}
                                      style={{ width: '250px' }}
                                    >
                                      <option value='0'>
                                        -- Select a visit --
                                      </option>
                                      {passtVisitLists.map(
                                        (option, index) => (
                                          <option
                                            key={index}
                                            data-visittypeid={option.Value}
                                            value={option.Value}
                                          >
                                            {option.Text}
                                          </option>
                                        ),
                                      )}
                                    </select>
                                  ) : (
                                    <div className='ml-2'>
                                      <b>No visit found.</b>
                                    </div>
                                  )}
                                </>
                              )}
                            </>
                          </div>
                        </td>
                        {userDetails.IsSLPA === 1 && (
                          <td>
                            <select
                              className='form-select form-select-sm'
                              onChange={handleSelectSupervisor}
                            >
                              {superVisorLists.map((option, index) => (
                                <option key={index} value={option.value}>
                                  {option.text}
                                </option>
                              ))}
                            </select>
                          </td>
                        )}
                      </>
                    )}
                    {activeTab === 'PN' && (
                      <>
                        <td>{formatDate(headerPOC) ?? 'Loading...'}</td>
                        <td>{formatDate(headerPN) ?? 'Loading...'}</td>
                      </>
                    )}
                    {activeTab === 'DS' && (
                      <>
                        <td>
                          {formatDate(headerPOC, false, true) ?? 'Loading...'}
                        </td>
                        <td>
                          {formatDate(headerDS, false, true) ?? 'Loading...'}
                        </td>
                      </>
                    )}
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      ) : (
        // <>
        //   <img
        //     alt='logo'
        //     style={{ widht: '180px', height: '70px' }}
        //     //src='./logoImage/logo.png'
        //     src={userDetails?.BrandLogo ? './logoImage/'+userDetails?.BrandLogo : './logoImage/logo.png'}
        //   />
        //   <Table
        //     responsive='sm'
        //     bordered
        //     style={{
        //       width: 'auto',
        //       marginLeft: '10px',
        //       padding: '50px',
        //       border: '1px solid #f0f0f0',
        //     }}
        //   >
        //     <tbody>
        //       <tr>
        //         <td style={{ width: '13%' }}>
        //           <h6>{userDetails.FullName.toUpperCase()}</h6>
        //         </td>
        //         <td>
        //           <b>Age</b>
        //         </td>
        //         <td>
        //           <b>Gender</b>
        //         </td>
        //         <td style={{ width: '12%' }}>
        //           <b>Primary Diagnosis</b>
        //         </td>
        //         {activeTab === 'SN' && (
        //           <td>
        //             <b>Visit</b>
        //           </td>
        //         )}
        //         <td>
        //           <b>Care Team</b>
        //         </td>
        //         {activeTab === 'SN' && (
        //           <>
        //             <td>
        //               <b>Date/Time of Assess</b>
        //             </td>
        //             {userDetails.IsSLPA === 1 && (
        //               <td>
        //                 <b>Supervisor</b>
        //               </td>
        //             )}
        //           </>
        //         )}
        //         {activeTab === 'PN' && (
        //           <>
        //             <td>
        //               <b>Start of Care</b>
        //             </td>
        //             <td>
        //               <b>Date of Progress Note</b>
        //             </td>
        //           </>
        //         )}
        //         {activeTab === 'DS' && (
        //           <>
        //             <td>
        //               <b>Start of Care</b>
        //             </td>
        //             <td>
        //               <b>Date of Discharge</b>
        //             </td>
        //           </>
        //         )}
        //       </tr>
        //       <tr>
        //         <td>
        //           <b>DOB:</b> {formatDate(userDetails.DOB, false, true)}
        //         </td>
        //         <td>{userDetails.Age}</td>
        //         <td>{userDetails.Gender}</td>
        //         <td>{userDetails.Diagnosisid1}</td>
        //         {activeTab === 'SN' && <td>{userDetails.VisitStatus}</td>}
        //         <td>
        //           {userDetails.CSTeam
        //             ? isTextTruncated
        //               ? `${userDetails.CSTeam.slice(0, 10)}...`
        //               : userDetails.CSTeam
        //             : 'NA'}
        //           {userDetails.CSTeam && (
        //             <>
        //               {isTextTruncated ? (
        //                 <span
        //                   onClick={toggleTextTruncation}
        //                   style={{ cursor: 'pointer', color: 'blue' }}
        //                 >
        //                   more...
        //                 </span>
        //               ) : (
        //                 <span
        //                   onClick={toggleTextTruncation}
        //                   style={{ cursor: 'pointer', color: 'blue' }}
        //                 >
        //                   {' '}
        //                   less...
        //                 </span>
        //               )}
        //             </>
        //           )}
        //         </td>
        //         {/* <td>
        //         {isTextTruncated
        //           ? `${userDetails.CSTeam?.slice(0, 10)}...`
        //           : userDetails.CSTeam
        //           ? userDetails.CSTeam
        //           : 'NA'}
        //         {isTextTruncated && (
        //           <span
        //             onClick={toggleTextTruncation}
        //             style={{ cursor: 'pointer', color: 'blue' }}
        //           >
        //             more...
        //           </span>
        //         )}
        //         {!isTextTruncated && (
        //           <span
        //             onClick={toggleTextTruncation}
        //             style={{ cursor: 'pointer', color: 'blue' }}
        //           >
        //             {' '}
        //             less...
        //           </span>
        //         )}
        //       </td> */}
        //         {activeTab === 'SN' && (
        //           <>
        //             <td>
        //               <div style={{ display: 'flex', alignItems: 'center' }}>
        //                 <b>Service Line:</b>
        //                 <>
        //                   {' '}
        //                   <select
        //                     className='form-select form-select-sm'
        //                     onChange={handleSelectServiceLine}
        //                     value={serviceLine}
        //                     style={{ width: '70px', marginLeft: '10px' }}
        //                   >
        //                     <option value='1'>ST</option>
        //                     <option value='2'>PT</option>
        //                     <option value='3'>OT</option>
        //                   </select>
        //                   {serviceLine === '1' && (
        //                     <select
        //                       className='form-select form-select-sm ml-2'
        //                       onChange={handleSelect}
        //                       value={ddlVisitId}
        //                       style={{ width: '300px' }}
        //                     >
        //                       <option value='0'>-- Select a visit --</option>
        //                       {assLists.map((option, index) => (
        //                         <option
        //                           key={index}
        //                           data-visittypeid={option.visitTypeId}
        //                           value={option.value}
        //                         >
        //                           {option.text}
        //                         </option>
        //                       ))}
        //                     </select>
        //                   )}
        //                   {(serviceLine === '2' || serviceLine === '3') && (
        //                     <>
        //                       {loadingSl ? (
        //                         <div className='ml-2'>Loading...</div> // Render loading message or spinner
        //                       ) : passtVisitLists &&
        //                         passtVisitLists.length > 0 ? (
        //                         <select
        //                           className='form-select form-select-sm ml-2'
        //                           onChange={handleSelectReport}
        //                           value={selectedVisitId}
        //                           style={{ width: '250px' }}
        //                         >
        //                           <option value='0'>
        //                             -- Select a visit --
        //                           </option>
        //                           {passtVisitLists.map((option, index) => (
        //                             <option
        //                               key={index}
        //                               data-visittypeid={option.Value}
        //                               value={option.Value}
        //                             >
        //                               {option.Text}
        //                             </option>
        //                           ))}
        //                         </select>
        //                       ) : (
        //                         <div className='ml-2'>
        //                           <b>No visit found.</b>
        //                         </div>
        //                       )}
        //                     </>
        //                     // <select
        //                     //   className='form-select form-select-sm ml-2'
        //                     //   onChange={handleSelect}
        //                     //   value={ddlVisitId}
        //                     //   style={{ width: '300px' }}
        //                     // >

        //                     //   {passtVisitLists.map((option, index) => (
        //                     //     <option
        //                     //       key={index}
        //                     //       data-visittypeid={option.Value}
        //                     //       value={option.Value}
        //                     //     >
        //                     //       {option.Text}
        //                     //     </option>
        //                     //   ))}
        //                     // </select>
        //                   )}
        //                 </>
        //               </div>
        //             </td>
        //             {userDetails.IsSLPA === 1 && (
        //               <td>
        //                 <select
        //                   className='form-select form-select-sm'
        //                   onChange={handleSelectSupervisor}
        //                 >
        //                   {superVisorLists.map((option, index) => (
        //                     <option key={index} value={option.value}>
        //                       {option.text}
        //                     </option>
        //                   ))}
        //                 </select>
        //               </td>
        //             )}
        //           </>
        //         )}
        //         {activeTab === 'PN' && (
        //           <>
        //             <td>{formatDate(headerPOC) ?? 'Loading...'}</td>
        //             <td>{formatDate(headerPN) ?? 'Loading...'}</td>
        //           </>
        //         )}
        //         {activeTab === 'DS' && (
        //           <>
        //             <td>
        //               {formatDate(headerPOC, false, true) ?? 'Loading...'}
        //             </td>
        //             <td>{formatDate(headerDS, false, true) ?? 'Loading...'}</td>
        //           </>
        //         )}
        //       </tr>
        //     </tbody>
        //   </Table>
        // </>
        <p>Loading client details...</p>
      )}

      {soapNoteData && (
        <SoapNoteMain
          data={soapNoteData}
          newVisitId={dynamicVisitId}
          isShowButton={isShowButton}
        ></SoapNoteMain>
      )}
      {progressNoteData && (
        <ProgressNoteMain
          data={progressNoteData}
          newVisitId={dynamicVisitId}
          isShowButton={isShowButton}
        ></ProgressNoteMain>
      )}

      {pocData && (
        <CHCMedicalPDF
          PocData={pocData}
          newVisitId={dynamicVisitId}
          isShowButton={isShowButton}
        ></CHCMedicalPDF>
      )}

      {dischargeData && (
        <DischargeSumPDF
          data={dischargeData}
          newVisitId={dynamicVisitId}
          isShowButton={isShowButton}
        ></DischargeSumPDF>
      )}
    </>
  )
}
