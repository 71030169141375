import React, { useState, useEffect, useContext } from 'react'
import { View, Text } from '@react-pdf/renderer'
import { reportStyle } from '../CSS/reportStyle'
import formatDate from '../Utility/Utility'
import { formatTime } from '../Utility/Utility'
import axios from 'axios'
import { Variables } from '../Variables'
import HeaderTextContent from './HeaderTextContent'

const ContentCHC = ({
  FirstName,
  HeOrShe,
  hisHer,
  userDetails,
  VisitId,
  clientdata,
  FullName,
  pocData,
  updateDiagnosis2,
}) => {
  const [isYes] = useState('Yes')
  const [chcGeneral, setChcGeneral] = useState(null)
  const [chcMedical, setChcMedical] = useState(null)

  useEffect(() => {
    if (!VisitId) return
    axios
      .get(Variables.API_URL + 'CaseHistory/General/' + VisitId)
      .then((response) => {
        const data = response.data.Result
        if (response.status === 200) {
          //alert(JSON.stringify(data));
          setChcGeneral(data)
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
      })

    axios
      .get(Variables.API_URL + 'CaseHistory/Medical/' + VisitId)
      .then((response) => {
        const data = response.data.Result
        if (response.status === 200) {
          //alert(JSON.stringify(data));
          setChcMedical(data)
          updateDiagnosis2(data.Diagnosis2)
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
      })
  }, [VisitId])
  function countCharacters(text) {
    return text.length
  }
  return (
    <>
      <View style={reportStyle.pageSpace}>
        {/* <View style={reportStyle.headerTextContainerText}>
          <Text style={reportStyle.headerText}>
            Date of Evaluation:{' '}
            {chcGeneral && formatDate(chcGeneral.CreatedDate, false, true)}
            {'   '}
            <Text style={reportStyle.headerTextSpace}> </Text>
            Start: {clientdata && formatTime(clientdata.Scheduleddatefrom)}{' '}
            {'   '}
            <Text style={reportStyle.headerTextSpace}> </Text>
            End: {clientdata && formatTime(clientdata.Scheduleddateto)} {'   '}
          </Text>
        </View>
        <HeaderTextContent
          clientdata={clientdata}
          FullName={FullName}
          PocData={pocData}
          Diagnosis2={chcMedical && chcMedical.Diagnosis2}
        ></HeaderTextContent> */}
        <View style={reportStyle.TextHeader}>
          <Text style={reportStyle.TextHeaderText}>Case History</Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            {FirstName}'s dominant language is{' '}
            {chcGeneral &&
              (chcGeneral.DominantLanguage === 'Other'
                ? chcGeneral.DominantLanguageOther
                : chcGeneral.DominantLanguage)}{' '}
            {chcGeneral &&
              chcGeneral.AdditionalLanguages &&
              'and ' +
                chcGeneral.AdditionalLanguages.replace(/,/g, ', ') +
                ' is spoken in the home'}
            . {HeOrShe} communicates through{' '}
            {chcGeneral && chcGeneral.CommunicatesThrough}, and{' '}
            {chcGeneral && chcGeneral.UnderstandingLevel} of what{' '}
            {HeOrShe.toLowerCase()} says is understood. {HeOrShe}{' '}
            {chcGeneral && chcGeneral.AttendSchool === isYes
              ? 'attends '
              : 'does not attend '}
            school/daycare.{' '}
            {chcGeneral && (
              <Text>
                {chcGeneral.AttendSchool === isYes ? (
                  <>
                    {HeOrShe} is currently in grade {chcGeneral.ClassGrade}.{' '}
                    {HeOrShe} is on a {chcGeneral.CurrentPlan} plan.
                  </>
                ) : (
                  <></>
                )}
              </Text>
            )}
          </Text>
        </View>
        {chcGeneral && chcGeneral.LivingSiblingDescription && (
          <>
            <View style={reportStyle.paragraph}>
              <Text break>
                {FirstName}'s current living situation is{': '}
                {chcGeneral.LivingSiblingDescription}.
              </Text>
            </View>
          </>
        )}{' '}
        {chcGeneral && chcGeneral.HasSocialSkillConcern === isYes && (
          <>
            <View style={reportStyle.paragraph}>
              <Text>
                {FirstName}’s family has the following concerns about {hisHer}{' '}
                social skills: {chcGeneral.SocialSkillConcernDescription}
              </Text>
            </View>
          </>
        )}
        <View style={reportStyle.paragraph}>
          <Text>
            {chcGeneral && chcGeneral.HasSpeechTherapyInPast === isYes ? (
              <>
                {FirstName} has had previous therapy at{' '}
                {chcGeneral.PastSpeechTherapyDescription} and{' '}
                {chcGeneral &&
                chcGeneral.ReceivedPastSpeechTherapyReport === isYes ? (
                  <>has received a report.</>
                ) : (
                  <>has not received a report.</>
                )}
              </>
            ) : (
              <>{FirstName} has not had previous therapy.</>
            )}

            {chcGeneral &&
            chcGeneral.IsReceivingOutsideAgencyService === isYes ? (
              <>
                {' '}
                {HeOrShe} is currently receiving speech therapy services through
                an outside agency from {chcGeneral.ReceivingOutsideAgencyWhere}.{' '}
                {HeOrShe} has also worked with the following specialists:{' '}
                {chcGeneral.OtherSpecialistsWorkedWith}
                {chcGeneral && chcGeneral.HaveYouReceiveReport === isYes ? (
                  <> and has received a report.</>
                ) : (
                  <> and has not received a report.</>
                )}
              </>
            ) : (
              <>
                {' '}
                {HeOrShe} is not currently receiving speech therapy services{' '}
                through an outside agency.
              </>
            )}
          </Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            {chcGeneral && chcGeneral.SpeechTherapyReason && (
              <>
                {FirstName}'s family is seeking therapy because{' '}
                {chcGeneral.SpeechTherapyReason}{' '}
                {chcGeneral.ExpectedOutcome && (
                  <>
                    and their expected outcome is {chcGeneral.ExpectedOutcome}.
                  </>
                )}
              </>
            )}
          </Text>
        </View>
        <View style={reportStyle.TextHeader} break>
          <Text style={reportStyle.TextHeaderText}>Medical History</Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            {chcMedical && chcMedical.Diagnosis1 && (
              <>
                {FirstName} was first diagnosed with {chcMedical.Diagnosis1}
                {chcMedical.Diagnosis1OnsetDate
                  ? ' on ' +
                    (formatDate(chcMedical.Diagnosis1OnsetDate, false, true) +
                      '.')
                  : '.'}
              </>
            )}
            {chcMedical && chcMedical.Diagnosis2 && (
              <Text>
                {' '}
                {HeOrShe} was also diagnosed with {chcMedical.Diagnosis2}
                {chcMedical.Diagnosis2OnsetDate
                  ? ' on ' +
                    formatDate(chcMedical.Diagnosis2OnsetDate, false, true) +
                    '.'
                  : '.'}
              </Text>
            )}
            {chcMedical &&
              chcMedical.Diagnosis3 !== null &&
              chcMedical.Diagnosis3.trim() !== '' && (
                <Text>
                  {' '}
                  {HeOrShe} was also diagnosed with {chcMedical.Diagnosis3}
                  {chcMedical.Diagnosis3OnsetDate
                    ? ' on ' +
                      (formatDate(chcMedical.Diagnosis3OnsetDate, false, true) +
                        '.')
                    : '.'}
                </Text>
              )}

            {/*{chcMedical && chcMedical.Diagnosis4 && (
            <Text>
              {HeOrShe} was also diagnosed with {chcMedical.Diagnosis4} on{" "}
              {formatDate(chcMedical.Diagnosis2OnsetDate, false)}.
            </Text>
          )}
           The resulting speech and language deficit affects {HisOrHer}{" "}
      life by {DeficitEffect}. */}
          </Text>
        </View>
        {chcMedical && chcMedical.DevelopmentalDiagnoses && (
          <>
            <View style={reportStyle.paragraph}>
              <Text>
                {FirstName}'s family reported {hisHer} developmental diagnosis{' '}
                as {chcMedical.DevelopmentalDiagnoses}{' '}
              </Text>
            </View>
          </>
        )}
        <View style={reportStyle.paragraph}>
          {chcMedical && chcMedical.BehavioralDiagnoses && (
            <>
              <Text>
                {FirstName}'s family reported {hisHer} behavioral diagnosis as{' '}
                {chcMedical.BehavioralDiagnoses}{' '}
              </Text>
            </>
          )}

          {chcGeneral && chcGeneral.DeficitEffect && (
            <>
              <Text>
                The resulting speech and language deficit affects {hisHer} life{' '}
                by {chcGeneral.DeficitEffect}
              </Text>
            </>
          )}

          <Text>
            {chcMedical && chcMedical.BirthHistoryType && (
              <>
                {FirstName}'s prenatal and birth history were{' '}
                {chcMedical.BirthHistoryType}.{' '}
                {chcMedical.BirthHistoryType === 'Complicated' && (
                  <>
                    Complications included: {chcMedical.BirthHistoryDescription}
                  </>
                )}
              </>
            )}
          </Text>
        </View>
        {chcGeneral && (
          <>
            <View style={reportStyle.paragraph}>
              <Text style={{ paddingBottom: 0 }}>
                {FirstName} was first able to:
              </Text>
              <Text style={{ paddingLeft: 10, marginTop: 0 }}>
                1){' '}
                {chcGeneral.SitupAge !== 0
                  ? `Sit Up at ${chcGeneral.SitupAge} months`
                  : 'Unable to Sit Up'}
                {'\n'}2){' '}
                {chcGeneral.CrawlAge !== 0
                  ? `Crawl at ${chcGeneral.CrawlAge} months`
                  : 'Unable to Crawl'}
                {'\n'}3){' '}
                {chcGeneral.StandAge !== 0
                  ? `Stand at ${chcGeneral.StandAge} months`
                  : 'Unable to Stand'}
                {'\n'}4){' '}
                {chcGeneral.WalkAge !== 0
                  ? `Walk at ${chcGeneral.WalkAge} months`
                  : 'Unable to Walk'}
                {'\n'}5){' '}
                {chcGeneral.SpeakWordAge !== 0
                  ? `Speak Words at ${chcGeneral.SpeakWordAge} months`
                  : 'Unable to Speak Words'}
                {'\n'}6){' '}
                {chcGeneral.ComWordsAge !== 0
                  ? `Combine Words at ${chcGeneral.ComWordsAge} months`
                  : 'Unable to Combine Words'}
              </Text>
            </View>
          </>
        )}
        <View style={reportStyle.paragraph}>
          <Text>
            {chcMedical && chcMedical.HadHeadInjury && (
              <>
                {FirstName}{' '}
                {chcMedical.HadHeadInjury === isYes ? 'has' : 'has not'} had a{' '}
                head injury.{' '}
                {chcMedical.HadHeadInjury === isYes
                  ? 'The injury was described as: ' +
                    chcMedical.HeadInjuryDescription +
                    ' '
                  : ''}
                {HeOrShe}{' '}
                {chcMedical.HadEarInfection === isYes ? 'has' : 'has not'} had{' '}
                ear infections.{' '}
                {chcMedical.HadEarInfection === isYes
                  ? 'as often as ' + chcMedical.EarInfectionFrequency
                  : ''}
                {/* and{' '}
                {chcMedical.HadEarInfection ? <>{'does'}</> : <>{'does not'}</>}
                {' have PE tubes.'} */}
                {HeOrShe}{' '}
                {chcMedical.IsUsingPacifier === isYes ? 'does' : 'does not'} use{' '}
                a pacifier{' '}
                {chcMedical.IsUsingPacifier === isYes
                  ? 'when ' + chcMedical.PacifierUseFrequency + '.'
                  : ''}
              </>
            )}
          </Text>
        </View>
        <View style={{ ...reportStyle.paragraph }}>
          <Text>
            {FirstName} has{' '}
            {chcMedical && (
              <>
                {chcMedical.HasAllergy === isYes
                  ? 'the following allergies ' +
                    chcMedical.AllergyDescription +
                    '.'
                  : 'no allergies.'}
              </>
            )}{' '}
            {HeOrShe} is currently on{' '}
            {chcMedical && (
              <>
                {chcMedical.IsOnMedication === isYes
                  ? 'the following medications ' +
                    chcMedical.MedicationDescription +
                    ', '
                  : 'no medications, '}
              </>
            )}
            {chcMedical && (
              <>
                {chcMedical.PreviousSurgeriesDescription
                  ? 'and has had the following surgical procedures ' +
                    chcMedical.PreviousSurgeriesDescription +
                    '.'
                  : 'and has had no surgical procedures. '}
              </>
            )}
          </Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            {FirstName}{' '}
            {chcMedical && (
              <>
                {chcMedical.DoesWearGlasses === isYes
                  ? 'wears glasses.'
                  : 'does not wear glasses '}
                {chcMedical.LastVistionScreeningDate && (
                  <>
                    and they {chcMedical.LastVistionScreeningResult} their{' '}
                    latest vision screening on{' '}
                    {formatDate(
                      chcMedical.LastVistionScreeningDate,
                      false,
                      true,
                    )}
                    .
                  </>
                )}
              </>
            )}
          </Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            {FirstName}{' '}
            {chcMedical && (
              <>
                {chcMedical.DoesWearHearingAids === isYes
                  ? 'wears hearing aids '
                  : 'does not wear hearing aids '}
                {chcMedical.LastHearingScreeningDate && (
                  <>
                    and they{' '}
                    {chcMedical.LastHearingScreeningResult.toLowerCase()} their{' '}
                    latest hearing screening on{' '}
                    {formatDate(
                      chcMedical.LastHearingScreeningDate,
                      false,
                      true,
                    )}
                    .
                  </>
                )}
              </>
            )}
          </Text>
        </View>
        <View style={{ ...reportStyle.paragraph }}>
          <Text>
            {chcGeneral && chcGeneral.HasFamilyDefict && (
              <>
                There
                {chcGeneral.HasFamilyDefict === isYes ? ' is ' : ' is not '}a
                family history of speech and language deficits or learning
                difficulties,
                {chcGeneral.HasFamilyDefict === isYes && (
                  <> which includes {chcGeneral.FamilyDefictDescription}</>
                )}
              </>
            )}
            {chcMedical && chcMedical.OverallHealthDescription && (
              <>
                {' '}
                {FirstName}'s general overall health was described as{' '}
                {chcMedical.OverallHealthDescription}
              </>
            )}
          </Text>
          {/* <Text>
            Character Count:{" "}
            {countCharacters(
              FirstName +
                chcGeneral.DominantLanguage +
                (chcGeneral.AdditionalLanguages || "") +
                (chcGeneral.CommunicatesThrough || "") +
                (chcGeneral.UnderstandingLevel || "") +
                (chcGeneral.ClassGrade || "") +
                (chcGeneral.CurrentPlan || "")
            )}
          </Text> */}
        </View>
      </View>
    </>
  )
}

export default ContentCHC
